const WhiteAnnimeLogo = () => {
  return (
    <div>
      {/* <div className="absolute inset-0 bgRedblack w-[70px] h-[70px] -z-10 group-hover:w-[350px] transition-[width] duration-[1s]"></div> */}
      <svg
        id="dg-logo-white"
        className="max-w-[90vw] max-h-[90vh] w-[340px]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 4234 843"
      >
        <defs>
          <clipPath id="clip-letters">
            <rect x="623.32" width="3300" height="843" fill="#fff" />
          </clipPath>
          <clipPath
            id="clip-d"
            x="230.24"
            y="193.34"
            width="3795.05"
            height="426.64"
            maskUnits="userSpaceOnUse"
          >
            <polygon
              id="d-mask"
              points="4025.29 193.34 4025.29 524.8 3930.11 524.8 3930.11 619.98 230.24 619.98 230.24 193.34 4025.29 193.34"
              fill="#fff"
            />
          </clipPath>
        </defs>
        <g id="hiding-rect">
          <g clipPath="url(#clip-letters)">
            <g id="letters">
              <g id="let-1">
                <path
                  d="M709.36,285.68c30.78,0,51.48-20.16,51.48-47.76,0-25.47-20.7-44.58-51.48-44.58s-51.48,20.17-51.48,46.17S678.58,285.68,709.36,285.68Z"
                  fill="#fff"
                />
                <rect
                  x="667.96"
                  y="325.48"
                  width="82.79"
                  height="285.52"
                  fill="#fff"
                />
              </g>
              <path
                id="let-2"
                d="M1043.58,325.48V362.1c-21.76-27.59-54.66-40.86-94.46-40.86-79.08,0-142.76,54.66-142.76,138s63.68,138,142.76,138c37.15,0,68.46-11.67,90.21-35.55v12.2c0,51.48-25.47,78-83.85,78-36.61,0-76.42-12.73-100.83-32.37l-32.9,59.44c33.43,26,86,39.27,140.1,39.27,103,0,160.27-48.82,160.27-155V325.48Zm-78,203.79c-43.52,0-75.36-28.12-75.36-70s31.84-70.05,75.36-70.05,74.83,28.13,74.83,70.05S1009.09,529.27,965.57,529.27Z"
                fill="#fff"
              />
              <g id="let-3">
                <path
                  d="M1240.57,285.68c30.78,0,51.48-20.16,51.48-47.76,0-25.47-20.7-44.58-51.48-44.58s-51.47,20.17-51.47,46.17S1209.79,285.68,1240.57,285.68Z"
                  fill="#fff"
                />
                <rect
                  x="1199.18"
                  y="325.48"
                  width="82.79"
                  height="285.52"
                  fill="#fff"
                />
              </g>
              <path
                id="let-4"
                d="M1520.66,538.83c-9,6.89-21.22,10.61-33.43,10.61-22.29,0-35.56-13.27-35.56-37.68V395.54h71.12V331.85h-71.12V262.33h-82.78v69.52h-44.05v63.69h44.05V512.82c0,67.93,39.27,102.43,106.67,102.43,25.47,0,50.41-5.84,67.39-18Z"
                fill="#fff"
              />
              <path
                id="let-5"
                d="M1704.59,321.24c-45.64,0-91.81,12.21-123.12,34.49l29.72,57.85c20.69-16.45,52-26.53,82.25-26.53,44.58,0,65.81,20.69,65.81,56.25h-65.81c-87,0-122.59,35-122.59,85.44,0,49.36,39.8,86.51,106.67,86.51,41.93,0,71.65-13.8,87-39.81V611H1842V448.08C1842,361.57,1791.62,321.24,1704.59,321.24Zm54.66,199.54c-9.55,26-33.43,38.74-61,38.74-29.19,0-46.7-13.8-46.7-34.49,0-19.11,12.2-33.44,51-33.44h56.78Z"
                fill="#fff"
              />
              <rect
                id="let-6"
                x="1916.97"
                y="217.22"
                width="82.79"
                height="393.78"
                fill="#fff"
              />
              <path
                id="let-7"
                d="M2483.74,528.21c-21.76,11.68-43.51,15.92-66.87,15.92-71.11,0-120.46-49.35-120.46-118.87,0-70.59,49.35-118.88,121.53-118.88,37.68,0,69,13.27,96,41.92l55.19-50.94c-36.08-41.93-90.21-64.22-155.49-64.22-118.35,0-204.32,80.14-204.32,192.12s86,192.11,202.73,192.11c53.07,0,109.32-16.45,150.19-47.23V419.42h-78.55Z"
                fill="#fff"
              />
              <path
                id="let-8"
                d="M2715.38,363.17V325.48h-79.07V611h82.79V476.2c0-54.66,30.25-79.6,75.89-79.6,6.37,0,11.67.53,18.57,1.06V321.24C2769.51,321.24,2735.55,335.57,2715.38,363.17Z"
                fill="#fff"
              />
              <path
                id="let-9"
                d="M3139.87,469.3c0-91.81-64.74-148.06-148.59-148.06-87,0-152.31,61.56-152.31,147,0,84.91,64.21,147,162.92,147,51.48,0,91.28-15.92,117.82-46.17l-44.05-47.77c-19.64,18.58-41.39,27.6-71.64,27.6-43.52,0-73.77-21.76-81.73-57.32h216C3138.81,484.69,3139.87,475.67,3139.87,469.3Zm-218.64-26c5.83-36.62,32.9-59.44,70.58-59.44,37.15,0,64.21,23.35,70.05,59.44Z"
                fill="#fff"
              />
              <path
                id="let-10"
                d="M3411,325.48V362.1c-21.76-27.59-54.67-40.86-94.47-40.86-79.07,0-142.76,54.66-142.76,138s63.69,138,142.76,138c37.15,0,68.46-11.67,90.22-35.55v12.2c0,51.48-25.47,78-83.85,78-36.62,0-76.42-12.73-100.83-32.37l-32.91,59.44c33.44,26,86,39.27,140.11,39.27,102.95,0,160.27-48.82,160.27-155V325.48Zm-78,203.79c-43.51,0-75.36-28.12-75.36-70s31.85-70.05,75.36-70.05,74.83,28.13,74.83,70.05S3376.49,529.27,3333,529.27Z"
                fill="#fff"
              />
              <path
                id="let-11"
                d="M3782.58,325.48V362.1c-21.76-27.59-54.66-40.86-94.47-40.86-79.07,0-142.75,54.66-142.75,138s63.68,138,142.75,138c37.15,0,68.46-11.67,90.22-35.55v12.2c0,51.48-25.47,78-83.85,78-36.62,0-76.42-12.73-100.83-32.37l-32.9,59.44c33.43,26,86,39.27,140.1,39.27,103,0,160.27-48.82,160.27-155V325.48Zm-78,203.79c-43.51,0-75.35-28.12-75.35-70s31.84-70.05,75.35-70.05,74.83,28.13,74.83,70.05S3748.08,529.27,3704.56,529.27Z"
                fill="#fff"
              />
            </g>
          </g>
        </g>
        <g id="hiding-d">
          <g clipPath="url(#clip-d)">
            <path
              id="main-letter"
              d="M230.24,610.66H407.8c127.86,0,215.52-77.8,215.52-197.33S535.66,216,407.8,216H314.13v82.22H230.24Zm172-312.44c77.61,0,129.54,39.56,129.54,115.11S480.94,535.68,403.33,535.68l-89.2,1V298.22Z"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
        </g>
        <g id="logo-dot">
          <path d="M3938.7,533.39h78v78h-78Z" fill="#fff" />
        </g>
      </svg>
    </div>
  );
};

export default WhiteAnnimeLogo;
